import cuid from 'cuid'
import router from '@/router'
import _ from 'lodash'

import * as types from '@/types'
import moment from 'moment'
import ApiService from '@/modules/api/services/ApiService'
import PAYMENT_METHODS from '@/modules/cart/constants/payment-methods'

const state = {
  cart: {
    entries: [],
    products: []
  },
  barionCart: {
    contents: [],
    currency: process.env.VUE_APP_CURRENCY,
    revenue: 0.0,
    step: 1
  },
  barionPaymentCart: {
    contents: [],
    currency: process.env.VUE_APP_CURRENCY,
    revenue: 0.0,
    step: 2
  },
  paymentMethod: PAYMENT_METHODS.CREDIT_CARD.type,
  cartDiscounts: null,
  selectedCoupons: [],
  handovers: [],
  handoversStatus: null,
  handoverId: null,
  transactionNewStatus: null,
  couponStatus: null,
  entryCountdownTimer: null,
  timerIntervalID: null,
  userCredit: null
}

const actions = {
  async [types.TRANSACTION_NEW_REQUEST]({ commit }, data) {
    try {
      const { payload, userId, paymode } = data
      const url = `users/${userId}/transactions`
      const params = {
        paymode
      }
      const newTransaction = await ApiService.save(url, payload, params)
      commit(types.TRANSACTION_NEW_REQUEST_SUCCESS)
      return Promise.resolve(newTransaction)
    } catch (error) {
      commit(types.TRANSACTION_NEW_REQUEST_ERROR)
      return Promise.reject()
    }
  },
  async [types.HANDOVERS_REQUEST]({ commit }, cart) {
    try {
      const { data } = await ApiService.save('handovers', cart)
      commit(types.HANDOVERS_REQUEST_SUCCESS, data)
    } catch (error) {
      commit(types.HANDOVERS_REQUEST_ERROR)
    }
  },
  async [types.REMOVE_ENTRY_FROM_CART_REQUEST]({ state, commit, rootState }, entry) {
    try {
      await ApiService.delete(`/users/${rootState.profile.userId}`, `entries/${entry.entryId}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.REMOVE_ENTRY_FROM_CART, entry)
      if (!state.cart.entries.length) commit(types.RESET_CART_TIMEOUT)
    }
  },
  async [types.REMOVE_TEAM_ENTRY_FROM_CART_REQUEST]({ commit, rootState }, entry) {
    try {
      const entryPromises = []
      const deleteEntry = async entry => {
        await ApiService.delete(`/users/${rootState.profile.userId}`, `entries/${entry.entryId}`)
      }

      _.each(entry.memberEntries, entry => entryPromises.push(deleteEntry(entry)))

      await Promise.all(entryPromises)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit(types.REMOVE_TEAM_ENTRY_FROM_CART, entry)
      if (!state.cart.entries.length) commit(types.RESET_CART_TIMEOUT)
    }
  },
  async [types.COUPON_VALIDATION_REQUEST]({ commit }, payload) {
    try {
      const { cart, userId, couponCode } = payload
      const url = `users/${userId}/coupons/${couponCode}/cartdiscount`
      const { data } = await ApiService.save(url, cart)
      commit(types.COUPON_VALIDATION_REQUEST_SUCCESS, data)
      return Promise.resolve()
    } catch (error) {
      commit(types.COUPON_VALIDATION_REQUEST_ERROR)
      return Promise.reject()
    }
  },
  [types.ADD_ENTRY_TO_CART_ACTION]({ commit, dispatch }, item) {
    commit(types.ADD_ENTRY_TO_CART, item)
    commit(types.SET_CART_TIMEOUT, () => dispatch(types.SET_ENTRY_COUNTDOWN_TIMER))
  },
  [types.SET_ENTRY_COUNTDOWN_TIMER]({ state, commit, dispatch }) {
    commit(types.UPDATE_ENTRY_COUNTDOWN_TIMER)

    const minutes = moment.duration(state.entryCountdownTimer).minutes()
    const seconds = moment.duration(state.entryCountdownTimer).seconds()

    if (isNaN(minutes) && isNaN(seconds)) {
      commit(types.RESET_CART_TIMEOUT)
    }
    if (minutes <= 0 && seconds <= 0) {
      clearInterval(state.timerIntervalID)
      dispatch(types.REMOVE_ALL_ENTRIES_FROM_CART)
    }
  },
  async [types.REMOVE_ALL_ENTRIES_FROM_CART]({ state, commit, dispatch }) {
    const entryPromises = []

    _.each(state.cart.entries, entry => {
      if (entry.teamEntryId) {
        entryPromises.push(dispatch(types.REMOVE_TEAM_ENTRY_FROM_CART_REQUEST, entry))
      } else {
        entryPromises.push(dispatch(types.REMOVE_ENTRY_FROM_CART_REQUEST, entry))
      }
    })

    await Promise.all(entryPromises)
    commit(types.RESET_CART_TIMEOUT)
    router.push({ name: 'entriesRemoved' })
  }
}

const mutations = {
  [types.ADD_ENTRY_TO_CART](state, item) {
    if (item.teamEntryId) {
      state.cart.entries.push(item)
    } else {
      item.cartItemId = cuid()
      state.cart.entries.push(item)
    }
  },
  [types.SET_CART_TIMEOUT](state, callback) {
    if (!state.entryCountdownTimer || state.entryCountdownTimer == 'Invalid date') {
      const start = moment()
      const end = moment().add(parseInt(process.env.VUE_APP_CART_TIMEOUT, 10), 'minutes')
      localStorage.setItem('cartTimeout', end)
      const diff = end.diff(start)
      state.entryCountdownTimer = moment.duration(diff)
    }

    state.timerIntervalID = setInterval(callback, 1000)
  },
  [types.UPDATE_ENTRY_COUNTDOWN_TIMER](state) {
    const start = moment()
    const end = localStorage.getItem('cartTimeout')
    const diff = moment(end).diff(start)
    state.entryCountdownTimer = moment.duration(diff)
  },
  [types.RESET_CART_TIMEOUT](state) {
    state.entryCountdownTimer = null
    clearInterval(state.timerIntervalID)
    state.timerIntervalID = null
    localStorage.removeItem('cartTimeout')
  },
  [types.REMOVE_ENTRY_FROM_CART](state, item) {
    const deleteEntryCoupons = entry =>
      (state.selectedCoupons = [...state.selectedCoupons].filter(coupon => coupon.cartItemId !== entry.cartItemId))
    const index = state.cart.entries.indexOf(item)
    if (process.env.VUE_APP_BRANCH == 'swim') {
      var removeFromCartProperties = {
        contentType: 'Product',
        currency: item.fee.currency.toUpperCase(),
        id: item.entryId.toString(),
        name: item.race.name,
        quantity: 1.0,
        unit: 'pcs.',
        totalItemPrice: item.fee.amount,
        unitPrice: item.fee.amount
      }
      window['bp']('track', 'removeFromCart', removeFromCartProperties)
    }
    if (item.isTeam) {
      _.each(state.cart.entries, entry => {
        if (entry.teamEntryId) {
          const foundEntry = _.find(entry.memberEntries, { entryId: item.entryId })

          if (foundEntry.coupon) {
            deleteEntryCoupons(foundEntry)
          }

          entry.memberEntries.splice(entry.memberEntries.indexOf(foundEntry), 1)
        }
      })
    }

    if (index > -1) {
      const hasCoupon = item.coupon
      if (hasCoupon) {
        deleteEntryCoupons(item)
      }

      state.cart.entries.splice(index, 1)
    }
  },
  [types.REMOVE_TEAM_ENTRY_FROM_CART](state, entry) {
    const index = state.cart.entries.indexOf(entry)
    const cartItem = _.find([...state.cart.entries], { teamEntryId: entry.teamEntryId })

    _.each(cartItem.memberEntries, entry => {
      if (entry.coupon) {
        state.selectedCoupons = [...state.selectedCoupons].filter(coupon => coupon.cartItemId !== entry.cartItemId)
      }
    })

    state.cart.entries.splice(index, 1)
  },
  [types.UPDATE_CART_TEAM_ENTRY](state, { teamEntryId, memberEntries }) {
    const cartItem = _.find(state.cart.entries, { teamEntryId })
    cartItem.memberEntries = memberEntries
  },
  [types.ADD_PRODUCT_TO_CART](state, item) {
    for (let i = 0; i < item.quantity; i++) {
      const product = Object.assign({}, item)
      product.quantity = 1
      product.cartItemId = cuid()
      state.cart.products.push(product)
    }
  },
  [types.ADD_TO_BARION_CART](state) {
    state.barionCart.contents = []
    state.barionCart.revenue = 0.0

    state.cart.products.forEach(function (product) {
      const barionProduct = {
        contentType: 'Product',
        currency: product.price.currency.toUpperCase(),
        id: product.pid.toString(),
        name: product.name,
        quantity: 1.0,
        unit: 'pcs.',
        unitPrice: product.price.amount,
        totalItemPrice: product.price.amount
      }
      state.barionCart.revenue += product.price.amount
      state.barionCart.contents.push(barionProduct)
    })
    state.cart.entries.forEach(function (entry) {
      const barionEntry = {
        contentType: 'Product',
        currency: entry.fee.currency.toUpperCase(),
        id: entry.entryId.toString(),
        name: entry.race.name,
        quantity: 1.0,
        unit: 'pcs.',
        unitPrice: entry.fee.amount,
        totalItemPrice: entry.fee.amount
      }
      state.barionCart.revenue += entry.fee.amount
      state.barionCart.contents.push(barionEntry)
    })
    window['bp']('track', 'initiateCheckout', state.barionCart)
  },
  [types.ADD_TO_BARION_CART_BEFORE_PAYMENT](state, total) {
    state.barionPaymentCart.contents = []
    state.barionPaymentCart.revenue = 0.0
    state.barionPaymentCart.step = 2
    state.cart.products.forEach(function (product) {
      const barionProduct = {
        contentType: 'Product',
        currency: product.price.currency.toUpperCase(),
        id: product.pid.toString(),
        name: product.name,
        quantity: 1.0,
        unit: 'pcs.',
        unitPrice: product.price.amount,
        totalItemPrice: product.price.amount
      }

      state.barionPaymentCart.contents.push(barionProduct)
    })
    state.cart.entries.forEach(function (entry) {
      const barionEntry = {
        contentType: 'Product',
        currency: entry.fee.currency.toUpperCase(),
        id: entry.entryId.toString(),
        name: entry.race.name,
        quantity: 1.0,
        unit: 'pcs.',
        unitPrice: entry.fee.amount,
        totalItemPrice: entry.fee.amount
      }
      state.barionPaymentCart.contents.push(barionEntry)
    })
    state.barionPaymentCart.revenue = total
    window['bp']('track', 'initiatePurchase', state.barionPaymentCart)
  },
  [types.BARION_CART_AFTER_PAYMENT](state) {
    state.barionPaymentCart.step = 3
    window['bp']('track', 'purchase', state.barionPaymentCart)
  },
  [types.REMOVE_PRODUCT_FROM_CART](state, item) {
    const index = state.cart.products.indexOf(item)
    if (process.env.VUE_APP_BRANCH == 'swim') {
      var removeToCartProperties = {
        contentType: 'Product',
        currency: item.price.currency.toUpperCase(),
        id: item.pid.toString(),
        name: item.name,
        quantity: item.quantity,
        unit: 'pcs.',
        unitPrice: item.price.amount,
        totalItemPrice: item.price.amount
      }
      window['bp']('track', 'removeFromCart', removeToCartProperties)
    }
    if (index > -1) {
      const hasCoupon = item.coupon
      if (hasCoupon) {
        state.selectedCoupons = [...state.selectedCoupons].filter(coupon => coupon.cartItemId !== item.cartItemId)
      }

      state.cart.products.splice(index, 1)
    }
  },
  [types.TRANSACTION_NEW_REQUEST_SUCCESS](state) {
    state.transactionNewStatus = 'success'
  },
  [types.TRANSACTION_NEW_REQUEST_ERROR](state) {
    state.transactionNewStatus = 'error'
  },
  [types.HANDOVERS_REQUEST_SUCCESS](state, handovers) {
    state.handovers = handovers
    state.handoversStatus = 'success'
  },
  [types.HANDOVERS_REQUEST_ERROR](state) {
    state.handoversStatus = 'error'
  },
  [types.COUPON_VALIDATION_REQUEST_SUCCESS](state, { data: coupon }) {
    if (!state.cartDiscounts.includes(coupon)) {
      state.cartDiscounts.push(coupon)
    }
    state.couponStatus = 'success'
  },
  [types.COUPON_VALIDATION_REQUEST_ERROR](state) {
    state.couponStatus = 'error'
  },
  [types.UPDATE_CART_WITH_DISCOUNT](state, cart) {
    state.cart = cart
  },
  [types.ADD_COUPON](state, coupon) {
    state.selectedCoupons.push(coupon)
  },
  [types.REMOVE_COUPON](state, coupon) {
    const index = state.selectedCoupons.indexOf(coupon)

    if (index > -1) {
      state.selectedCoupons.splice(index, 1)
    }

    const foundInProducts = state.cart.products.find(product => product.cartItemId === coupon.cartItemId)
    let foundInEntries = null

    state.cart.entries.forEach(entry => {
      const teamEntry =
        entry.memberEntries && entry.memberEntries.find(memberEntry => memberEntry.cartItemId === coupon.cartItemId)

      if (teamEntry) {
        foundInEntries = teamEntry
      }

      if (entry.cartItemId === coupon.cartItemId) {
        foundInEntries = entry
      }
    })

    if (foundInProducts) {
      delete foundInProducts.coupon
    }

    if (foundInEntries) {
      delete foundInEntries.coupon
    }
  },
  [types.REMOVE_COUPONS](state) {
    state.selectedCoupons = []
  },
  [types.SET_HANDOVER_ID](state, handoverId) {
    state.handoverId = handoverId
  },
  [types.SET_CART_ITEM_DELIVERY](state, { deliveryId, deliveryType, deliveries, isSelectedItemTeamEntry }) {
    let cartItem
    const teamEntries = _.filter(state.cart.entries, entry => entry.teamEntryId)

    if (isSelectedItemTeamEntry) {
      const memberEntries = _.flatMap(teamEntries, 'memberEntries')
      cartItem = _.find(memberEntries, { cartItemId: deliveries.cartItemId })
    } else {
      cartItem = _.find(
        [...state.cart.products, ...state.cart.entries],
        item => item.cartItemId === deliveries.cartItemId
      )
    }

    delete cartItem.delivery
    if (deliveryType == 'handover') {
      cartItem.handoverId = deliveryId
      delete cartItem.shippingId
    } else {
      cartItem.shippingId = deliveryId
      delete cartItem.handoverId
    }
  },
  [types.SET_CART_SHIPPING_COST](state, shippingCost) {
    state.cart.shipping = {
      amount: shippingCost,
      currency: process.env.VUE_APP_CURRENCY
    }
  },
  [types.CLEAR_SHIPPING](state) {
    _.each([...state.cart.products, ...state.cart.entries], item => {
      if (item.teamEntryId) {
        _.each(item.memberEntries, entry => {
          delete entry.delivery
          delete entry.handoverId
          delete entry.shippingId
          return entry
        })
      }

      delete item.delivery
      delete item.handoverId
      delete item.shippingId
      return item
    })

    state.cart.shipping = {}
  },
  [types.EMPTY_CART](state) {
    state.cart.entries = []
    state.cart.products = []
    state.selectedCoupons = []
  },
  [types.SET_USER_CREDIT](state, data) {
    state.userCredit = data
  },
  [types.SELECT_PAYMENT_METHOD](state, payment) {
    state.paymentMethod = payment
  }
}

const getters = {
  totalAmount: state => {
    let discount = 0
    let amount = 0

    state.cart.entries.forEach(item => {
      if (item.teamEntryId) {
        return (amount += item.memberEntries.reduce((acc, entry) => acc + entry.fee.amount, 0))
      }

      return (amount += item.fee.amount)
    })

    state.cart.products.forEach(item => (amount += item.price.amount))

    if (state.selectedCoupons.length) {
      state.selectedCoupons.forEach(item => (discount -= item.coupon.discount.amount))
    }

    return amount - discount
  },
  cartCount: state => {
    const entriesQuantity = state.cart.entries.reduce(acc => (acc += 1), 0)
    const count = state.cart.products.reduce((acc, product) => (acc += product.quantity), entriesQuantity)

    return count
  },
  getEntries: state => {
    const entries = _.filter([...state.cart.entries], entry => entry.entryId)
    const teamEntries = _.filter(state.cart.entries, entry => entry.teamEntryId)

    if (teamEntries.length) {
      const memberEntries = _.flatMap(teamEntries, 'memberEntries')
      _.each(memberEntries, entry => entries.push(entry))
    }

    return entries
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
